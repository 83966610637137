import { Box } from '@mui/material';
import React from 'react';
import NavBar from '../components/NavBar';
import axios from 'axios';

const Test = () => {
  const largeur = window.innerWidth;
  let margegauche = 30;
  if (largeur < 601) {
    margegauche = 0;
  }
  const [images, setImages] = React.useState([]);

  const handleImageChange = event => {
    setImages([...images, ...event.target.files]);
  };

  const handleFormSubmit = async(event) => {
    event.preventDefault();
    const formData = new FormData();
    images.forEach((image) => {
      formData.append(`image`, image);
      console.log(`Nom du fichier : ${image.name}`);
    });
    axios.post('http://api1.buyenjoy.store/nappy/', formData)
      .then(response => {
        console.log(response);
        alert(response.data);
      })
      .catch(error => {
        console.log(error);
        alert(error);
      });
  };

  return (
    <div>
      <NavBar />
      <Box sx={{ marginLeft: margegauche }}>
        <form onSubmit={handleFormSubmit}>
          <input type="file" accept="image/*" multiple name="files" onChange={handleImageChange} />
          <button type="submit">Upload</button>
        </form>
      </Box>
    </div>
  );
};

export default Test;
