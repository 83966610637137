import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import Badge from '@mui/material/Badge';
import { Avatar, ListItemButton } from "@mui/material";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const CartesOrders = ({ user, adress, date, total, products, payer,livrer,methode,typeDePaiement }) => {
  return (
    <Box
    elevation={0}
    sx={{
      minWidth: 275,
      width: 400,
      borderRadius: 6,
      boxShadow: "#282c342f 1px 5px 10px 0px",
      margin: 1,
      background: "#30336b",
      color: "white",
      "&:hover": {
        boxShadow: "white 0px 0px 10px 0px",
      },
    }}
    >
     <Badge 
     badgeContent={"Date de la commande : "+date} 
     color="secondary" 
     anchorOrigin={{
        vertical: 'top',
        horizontal: 'top',
      }}
     >
      <Card
        elevation={0}
        sx={{
          minWidth: 275,
          borderRadius: 6,
          boxShadow: "#282c342f 1px 5px 10px 0px",
          margin : 1,
          background:"#30336b", color : "white",'&:hover': {
            boxShadow : "white 0px 0px 10px 0px",
          },
        }}
      >
        <CardContent>
          <Box> 
            <Box>
              <Typography variant="h6">{user.name}, {user.phone}</Typography>
              <Typography variant="subtitle1">{adress.city} , {adress.street} , {adress.postalCode}</Typography>
              <Typography variant="subtitle1">{user.email}</Typography>
              <Typography variant="h6"> paiement : {payer ? methode : typeDePaiement ? "à la livraison" : "Aucun"}</Typography>
              <Typography variant="h6"> total : {total} CFA</Typography>
            </Box>
            <Accordion sx={{marginTop : 2, background : "none", color : "white", textAlign : "center"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
              <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                      bgcolor: 'none',
                      border : "#130f40 solid 2px",
                      borderRadius : 6,
                      marginTop : 1
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                      <Avatar alt={products[0].name} src={products[0].image[0]}  sx={{ width: 50, height: 50 }}/>
                      </ListItemAvatar>
                      <ListItemText primary={products[0].name} secondary={"quantité : " + products[0].cartNumber + " , prix : " + products[0].price + " CFA"} sx={{color : "white", '& .MuiListItemText-secondary': { color : "white"}}}/>
                      <ListItemButton>
                        <Button><DeleteRoundedIcon sx={{color: "white", fontSize : 30}}/></Button>
                      </ListItemButton>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </List>
         
                </AccordionSummary>
                <AccordionDetails>
                {
                products.map((produits, index)=>(
                    index === 0 ? "" :
                    <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                      bgcolor: 'none',
                      border : "#130f40 solid 2px",
                      borderRadius : 6,
                      marginTop : 1
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                      <Avatar alt={produits.name} src={produits.image[0]}  sx={{ width: 50, height: 50 }}/>
                      </ListItemAvatar>
                      <ListItemText primary={produits.name} secondary={"quantité : " + produits.cartNumber + " , prix : " + produits.price + " CFA"} sx={{color : "white", '& .MuiListItemText-secondary': { color : "white"}}}/>
                      <ListItemButton>
                        <Button><DeleteRoundedIcon sx={{color: "white", fontSize : 30}}/></Button>
                      </ListItemButton>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </List>
                   
                ))
            }
                </AccordionDetails>
              </Accordion>
          
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{display : "flex" , justifyContent : "space-around"}}>
          <Button sx={{ color: "#fff", background :"black", borderRadius: 6, width : "50%" }} variant="contained">
           {payer ? "payer" : "non payer"}
          </Button>
          <Button sx={{ color: "#fff", background :"black", borderRadius: 6, width : "50%"  }} variant="contained">
            {livrer ? "livrer" : "non livrer"}
          </Button>
        </CardActions>
        <CardActions sx={{display : "flex" , justifyContent : "space-around"}}>
          <Button sx={{ color: "#fff", background :"black", borderRadius: 6, width : "50%" }} variant="contained">
            Modifier
          </Button>
          <Button sx={{ color: "#fff", background :"black", borderRadius: 6, width : "50%"  }} variant="contained">
            supprimer
          </Button>
        </CardActions>
      </Card>
    </Badge>
    </Box>
  );
};

export default CartesOrders;
