import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Analyse from './Pages/Analyse';
import Home from './Pages/Home';
import Orders from './Pages/Orders';
import OtherPanier from './Pages/OtherPanier';
import Products from './Pages/Products';
import Rdv from './Pages/Rdv';
import Test from './Pages/Test';
import Users from './Pages/Users';

function App() {
  return (
    <div className="App">
      <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/products' element={<Products/> } />
          <Route path='/orders' element={<Orders/>} />
          <Route path='/users' element={<Users/>} />
          <Route path='/*' element={<Home/>} />
          <Route path='/rdv' element={<Rdv/>} />
          <Route path='/analyse' element={<Analyse/>} />
          <Route path='/other-panier' element={<OtherPanier/>} />
          <Route path='/test' element={<Test/>} />
      </Routes>
    </div>
  );
}

export default App;
