import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Avatar,
  AvatarGroup,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItem,
  OutlinedInput,
  Switch,
  TextField,
} from "@mui/material";
import {
  doc,
  deleteDoc,
  addDoc,
  getDoc,
  collection,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import Modal from "@mui/material/Modal";
import Snackbar from '@mui/material/Snackbar';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CartesProd = ({ image, name, describe, price, actif, identifiant }) => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [OpenModif, setOpenModif] = React.useState(false);
  const [dataModif, setDataModif] = React.useState({});
  const [images, setImages] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openalert, setOpenalert] = React.useState(false);
  const [openalertoff, setOpenalertoff] = React.useState(false);
  const [openalertgen, setOpenalertgen] = React.useState(false);
  
  function handleFilesChange(files) {
    setImages([...images, ...files]);
    const imageUrls = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileName =
        "https://www.nappylocks.com/database/images/produits/" + file.name;
      imageUrls.push(fileName);
    }
    console.log(imageUrls);
    return imageUrls;
  }
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleFormSubmit = async () => {
    setOpen(true);
    const formData = new FormData();
    images.forEach((image) => {
      formData.append(`image`, image);
      console.log(`Nom du fichier : ${image.name}`);
    });
    await axios
      .post("https://api1.buyenjoy.store/nappy/", formData)
      .then((response) => {
        setOpen(false);
        console.log(response);
        setOpenalert(true);
      })
      .catch((error) => {
        setOpen(false);
        console.log(error);
        setOpenalertoff(true)
      });
  };

  const handleChange = (value, titre) => {
    const titres = titre;
    setDataModif((prevState) => ({
      ...prevState,
      [titres]: value,
    }));
  };
  const ouvrirModif = () => {
    setOpenModif(true);
  };
  const modificationProduits = async (identifiant) => {
    const coll = doc(db, "products", identifiant);
    await updateDoc(coll, dataModif);
    setOpenalertgen(true);
    window.location.reload();
  };
  const desactiverProducts = async (identifiant) => {
    const result = !actif;
    const coll = doc(db, "products", identifiant);
    await updateDoc(coll, {
      isActive: result,
    });
    alert(
      "article " + result ? "desactiver avec succes" : "activer avec succes"
    );
    setTimeout(window.location.reload(), 4000);
  };

  const duplicateDocument = async (collectionPath, documentId) => {
    const sourceCollectionRef = collection(db, collectionPath);
    const sourceDocRef = doc(sourceCollectionRef, documentId);
    const sourceDocSnapshot = await getDoc(sourceDocRef);

    if (sourceDocSnapshot.exists()) {
      const newDocumentData = sourceDocSnapshot.data();
      await addDoc(sourceCollectionRef, newDocumentData);
    }
    alert("article dupliquer");
    window.location.reload();
  };
  const deleteProd = async (identifiant) => {
    setOpenDelete(true);
  };

  const ok = async (identifiant) => {
    await deleteDoc(doc(db, "products", identifiant));
    window.location.reload();
  };

  return (
    <Box component="main">
      <Card
        elevation={0}
        sx={{
          minWidth: 275,
          width: 400,
          borderRadius: 6,
          boxShadow: "#282c342f 1px 5px 10px 0px",
          margin: 1,
          background: "#30336b",
          color: "white",
          "&:hover": {
            boxShadow: "white 0px 0px 10px 0px",
          },
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <AvatarGroup max={4}>
              {image.map((imgs, index) => (
                <Avatar
                  key={index}
                  alt={"image" + index}
                  src={imgs}
                  sx={{ width: 85, height: 85 }}
                />
              ))}
            </AvatarGroup>
            <Box sx={{ marginLeft: 0 }}>
              <Accordion
                sx={{
                  marginTop: 2,
                  background: "none",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6"> {name} </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="subtitle1"> {describe} </Typography>
                </AccordionDetails>
              </Accordion>
              <Typography variant="h6"> {price} CFA</Typography>
            </Box>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            sx={{
              color: "#fff",
              background: "black",
              borderRadius: 6,
              width: "50%",
            }}
            variant="contained"
            onClick={() => desactiverProducts(identifiant)}
          >
            {actif ? "desactiver" : "activer"}
          </Button>
          <Button
            sx={{
              color: "#fff",
              background: "black",
              borderRadius: 6,
              width: "50%",
            }}
            variant="contained"
            onClick={() => ouvrirModif()}
          >
            Modifier
          </Button>
        </CardActions>
        <CardActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            sx={{
              color: "#fff",
              background: "black",
              borderRadius: 6,
              width: "50%",
            }}
            variant="contained"
            onClick={() => duplicateDocument("products", identifiant)}
          >
            Dupliquer
          </Button>
          <Button
            sx={{
              color: "#fff",
              background: "black",
              borderRadius: 6,
              width: "50%",
            }}
            variant="contained"
            onClick={() => deleteProd()}
          >
            supprimer
          </Button>
        </CardActions>
      </Card>
      <Modal
        keepMounted
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Voulez-Vous vraiment supprimer ce produits ?
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            cette Action est ireverssible
          </Typography>
          <CardActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              sx={{
                color: "#fff",
                background: "black",
                borderRadius: 6,
                width: "50%",
              }}
              variant="contained"
              onClick={() => ok(identifiant)}
            >
              oui supprimer
            </Button>
            <Button
              sx={{
                color: "#fff",
                background: "black",
                borderRadius: 6,
                width: "50%",
              }}
              variant="contained"
              onClick={() => setOpenDelete(false)}
            >
              non retour
            </Button>
          </CardActions>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={OpenModif}
        onClose={() => setOpenModif(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h3">
            Modifier ici : {identifiant}
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            cette Action est ireverssible
          </Typography>
          <Divider />
          <br />
          <TextField
            fullWidth
            id="outlined-basic"
            label="Nom"
            variant="outlined"
            defaultValue={name}
            onChange={(e) => handleChange(e.target.value, "name")}
          />
          <br />
          <br />
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={6}
            defaultValue={describe}
            onChange={(e) => handleChange(e.target.value, "describe")}
          />
          <br />
          <br />
          <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel htmlFor="outlined-adornment-amount">prix</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              endAdornment={<InputAdornment position="end">CFA</InputAdornment>}
              label="prix"
              onChange={(e) => handleChange(parseInt(e.target.value), "price")}
              type="number"
              defaultValue={price}
            />
            <ListItem>
              <Typography>
                {" "}
                {actif ? "le produit est actif" : "le produits n'est pas actif"}
              </Typography>
              <Switch
                checked={actif}
                disabled
                inputProps={{ "aria-label": "controlled" }}
              />
            </ListItem>
          </FormControl>
          <CardActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <label htmlFor="image">images : </label>
            <input
              name="image"
              type="file"
              multiple
              placeholder="changer les images"
              onChange={(e) =>
                handleChange(handleFilesChange(e.target.files), "image")
              }
            />
          </CardActions>

          <Button onClick={() => console.log(dataModif)}>tester</Button>
          <CardActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              sx={{
                color: "#fff",
                background: "black",
                borderRadius: 6,
                width: "50%",
              }}
              variant="contained"
              onClick={async () => {
                await handleFormSubmit();
                modificationProduits(identifiant);
              }}
            >
              enregistrer
            </Button>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Button
              sx={{
                color: "#fff",
                background: "black",
                borderRadius: 6,
                width: "50%",
              }}
              variant="contained"
              onClick={() => setOpenModif(false)}
            >
              annuler
            </Button>
          </CardActions>
          <Snackbar

  anchorOrigin={{ vertical: 'bottom',
  horizontal: 'center', }}
  open={openalert}
  autoHideDuration={4000}
  onClose={handleClose}
  message="les images on été importer avec success"
/>
<Snackbar
  anchorOrigin={{ vertical: 'top',
  horizontal: 'center', }}
  open={openalertoff}
  onClose={handleClose}
  autoHideDuration={4000}
  message="il y a eu une erreur veillez contacter kevine "
/>
<Snackbar
  anchorOrigin={{ vertical: 'top',
  horizontal: 'center', }}
  open={openalertgen}
  onClose={handleClose}
  autoHideDuration={4000}
  message="tous a aussi été modifier avec succes "
/>
        </Box>
      </Modal>
    </Box>
  );
};

export default CartesProd;
