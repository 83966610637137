import React, { useState } from "react";
import NavBar from "../components/NavBar";
import { Fab, Grid, Card } from "@mui/material";
import CartesProd from "../components/minicomponents/CartesProd";
import {
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import AddIcon from "@mui/icons-material/Add";
import Skeleton from "@mui/material/Skeleton";
const Products = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = React.useState([]);
  const largeur = window.innerWidth;
  let margegauche = 30;
  if (largeur < 601) {
    margegauche = 0;
  }
  React.useEffect(() => {
    getProducts();
  }, []);
  const getProducts = async () => {
    const docRef = collection(db, "products");
    const q = query(docRef, orderBy("name"));
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach((res) => {
      const { name, image, price, describe, isActive } = res.data();
      dataArr.push({
        id: res.id,
        name,
        image,
        price,
        describe,
        isActive,
      });
    });
    setDocuments(dataArr);
    setIsLoading(false);
  };
  function numStr(a, b) {
    a = '' + a;
    b = b || ' ';
    var c = '',
        d = 0;
    while (a.match(/^0[0-9]/)) {
      a = a.substr(1);
    }
    for (var i = a.length-1; i >= 0; i--) {
      c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
      d++;
    }
    return c;
  }
  if (isLoading) {
    return (
      <div>
        <NavBar />
        <div>
          <Grid container spacing={2} sx={{ marginLeft: margegauche }}>
            {["1", "2","3","4","5","6","7","8","9","10"].map((produits, index) => (
              <Card
              key={index}
                sx={{
                  minWidth: 275,
                  width: 400,
                  boxShadow: "#282c342f 0px 0px 0px 0px",
                  margin: 1,
                  background : "#30336b",
                  borderRadius : 5
                }}
              >
                <Skeleton variant="circular" width={100} height={100} sx={{marginLeft : 20}} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="rectangular" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
              </Card>
              
            ))}
          </Grid>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <NavBar />
        <Grid container spacing={2} sx={{ marginLeft: margegauche }}>
          {documents.map((produits) => (
            <CartesProd
              key={produits.id}
              name={produits.name}
              describe={produits.describe}
              price={numStr(produits.price)}
              actif={produits.isActive}
              image={produits.image}
              identifiant={produits.id}
            />
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              left: "98%",
              top: "98%",
              transform: "translate(-98%, -98%)",
              height: 60,
              width: 100,
              background: "black",
            }}
          >
            <AddIcon sx={{ fontSize: 20 }} />
            ajouter
          </Fab>
        </Grid>
      </div>
    );
  }
};

export default Products;
