import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import Graph from '../components/minicomponents/Graph';
import { Grid } from '@mui/material';
const Analyse = () => {
    const [usersData, setUsersData] = useState([]);
    const gest = [
        { name: 'Janvier', value: 20 },
        { name: 'Février', value: 50 },
        { name: 'Mars', value: 80 },
        { name: 'Avril', value: 60 },
        { name: 'Mai', value: 70 },
        { name: 'Juin', value: 30 },
        { name: 'Juillet', value: 90 },
      ];
    const largeur = window.innerWidth;
  let margegauche = 30;
  if (largeur < 601) {
    margegauche = 0;
  }
    useEffect(() => {
       getData();
        }, []);

        const getData =()=> {
            const q = query(collection(db, 'users'), orderBy('createdAt'));
            const unsubscribe = onSnapshot(q, (snapshot) => {
              const users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
              const usersData = users.reduce((acc, user) => {
                const date = new Date(user.createdAt.seconds * 1000); // convert to milliseconds
                const month = date.toLocaleString('default', { month: 'long' });
                const year = date.getFullYear();
                const label = `${month} ${year}`;
                const index = acc.findIndex((item) => item.name === label);
                if (index === -1) {
                  acc.push({ name: label, value: 1 });
                } else {
                  acc[index].value += 1;
                }
                return acc;
              }, []);
              setUsersData(usersData);
            });
            return () => {
                unsubscribe();
              };
        }
    return (
        <div>
            {console.log(usersData)}
            <NavBar/>
            <Grid container spacing={2} sx={{ marginLeft: margegauche }}>
            <Graph data={gest}/>
            </Grid>
            
        </div>
    );
};

export default Analyse;