
import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import { collection, getDocs, query} from 'firebase/firestore';
import { db } from '../firebase';
import { Box, CircularProgress, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const Users = () => {
    const largeur =  window.innerWidth;
    let margegauche = 35
    let min = "75%"
    if (largeur<601) {
        margegauche = 1;
        min = "100%"
    }
    if (largeur< 1000){
    }
    const [isLoading,setIsLoading] = useState(true);
    const [documents,setDocuments] = useState([]);

    React.useEffect(() => {
        getClients();
    }, []);

    const getClients = async()=> {
        const docRef = collection(db, 'users');
        const q = query(docRef);
        const querySnapshot = await getDocs(q);
        const dataArr = [];
        querySnapshot.forEach((res) => {
            const { country, email, name, phone, createdAt,currency} = res.data();
            dataArr.push({
                id: res.id,
                country,
                email,
                name,
                phone,
                createdAt,
                currency
            });
        });
        setDocuments(dataArr)
        setIsLoading(false);
    }

    if (isLoading) {
        return(
            <div>
                <NavBar/>
            <div>
            <Box>
            <CircularProgress />
            </Box>
            </div>
            </div>
        )

    } else {  

        return (

        <div>
            <NavBar/>
                                        <TableContainer sx={{ marginLeft: margegauche, width: min, borderRadius : 6,boxShadow : "#282c342f 1px 5px 10px 0px",background:"white"  }}>
                                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                <TableHead >
                                                    <TableRow>
                                                        <StyledTableCell align="center">Pays</StyledTableCell>
                                                        <StyledTableCell align="center">devise</StyledTableCell>
                                                        <StyledTableCell align="center">Nom</StyledTableCell>
                                                        <StyledTableCell align="center">telephone</StyledTableCell>
                                                        <StyledTableCell align="center">date de creation</StyledTableCell>
                                                        <StyledTableCell align="center">email</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {documents.map((row) => (
                                                        <StyledTableRow key={row.id}>
                                                            <StyledTableCell align="center">{row.country}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.currency}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.name}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.phone}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.createdAt}</StyledTableCell>
                                                            <StyledTableCell align="center">{row.email}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

        </div>
    );
    };
}
export default Users;