import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, Divider } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
const drawerWidth = 1000;
const Cartes = ({ avatar, avatarName, titre, SousTitre, lien }) => {
    let color = {
      primaire : "white",
      background : "#30336b",
    }
  return (
    <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
    <Card elevation={0} sx={{ minWidth: 275, borderRadius: 6, boxShadow : "#282c342f 1px 5px 10px 0px", background:"#30336b", color : color.primaire, '&:hover': {
          boxShadow : "white 0px 0px 10px 0px",
        }, }}>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Avatar
            alt={avatarName}
            src={avatar}
            sx={{ width: 85, height: 85 }}
          />
          <Box sx={{marginLeft : 5}} >

          <Typography variant="subtitle1" > {SousTitre} </Typography>
          <Typography variant="h4" > {titre} </Typography>
          </Box>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Link to={lien} className="custom-link">
        <Button sx={{ color : color.primaire }}>
          voir plus <ArrowForwardIcon  sx={{fontSize : 20, marginLeft : 1}}/>{" "}
        </Button>
        </Link>
      </CardActions>
    </Card>
      </Box>
  );
};

export default Cartes;
