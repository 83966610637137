import { Box } from "@mui/material";
import React, { useState } from "react";
import Cartes from "../components/minicomponents/Cartes";
import NavBar from "../components/NavBar";
import { collection,getCountFromServer,getDocs, query, where} from 'firebase/firestore';
import { db } from '../firebase';
const drawerWidth = 250;
const Home = () => {
    const [sizeProducts,setSizeProducts] = useState(0);
    const [sizeCommande,setSizeCommande] = useState(0);
    const [sizeUsers,setSizeUsers] = useState(0);
    const [sizeRDV,setSizeRDV] = useState(0);
    const [sizeOtherPanier,setSizeOtherPanier] = useState(0);
    const [isLoading,setIsLoading] = useState(true);
    React.useEffect(() => {
        getSizeproducts();
        getSizeUsers();
        getSizeCommande();
        getSizeRDV();
        getSizeOtherPanier();
    }, []);

    const getSizeproducts = async()=> {
        const docRef = collection(db, 'products');
        const q = query(docRef);
        const querySnapshot = await getDocs(q);
        setSizeProducts(querySnapshot.size)
        setIsLoading(false);
    }
    const getSizeRDV = async()=> {
        const docRef = collection(db, 'rdv');
        const q = query(docRef);
        const querySnapshot = await getDocs(q);
        setSizeRDV(querySnapshot.size)
        setIsLoading(false);
    }
    const getSizeUsers = async()=> {
        const docRef = collection(db, 'users');
        const q = query(docRef);
        const querySnapshot = await getDocs(q);
        setSizeUsers(querySnapshot.size)
    }
    const getSizeCommande = async() => {
        const coll = collection(db, "commands");
        const query_ = query(coll, where('isPaied', '==', true));
        const snapshot = await getCountFromServer(query_);
        setSizeCommande(snapshot.data().count);
      }
    const getSizeOtherPanier = async() => {
        const coll = collection(db, "commands");
        const query_ = query(coll, where('isPaied', '==', false));
        const snapshot = await getCountFromServer(query_);
        setSizeOtherPanier(snapshot.data().count);
      }
    const largeur =  window.innerWidth;
    let margegauche = 30
    let mode = "flex"
    if (largeur<601) {
        margegauche = 0;
    }
    if (largeur< 1000){
        mode = "bloc"
    }
  return (
    <div>
      <NavBar />

      <Box sx={{display : mode , marginLeft :margegauche,flexWrap : "wrap", width: { sm: `calc(100% - ${drawerWidth}px)` },}}>  
     <Cartes SousTitre="Liste des Produits" titre={sizeProducts}  avatar='./img/products.png' lien={"/products"}/>
     <Cartes SousTitre="Liste des commandes" titre={sizeCommande}  avatar='./img/oders.png' lien={"/orders"}/>
     <Cartes SousTitre="Liste des utilisateurs" titre={sizeUsers}  avatar='./img/user.png' lien={"/users"}/>
      </Box>
      <Box sx={{display : mode , marginLeft :margegauche,flexWrap : "wrap", width: { sm: `calc(100% - ${drawerWidth}px)` },}}>
     <Cartes SousTitre="Statistiques des ventes" titre={"0"}  avatar='./img/stats.png' lien={"/analyse"}/>
     <Cartes SousTitre="autres options" titre={"0"}  avatar='./img/autre.png' lien={"/options"}/>
     <Cartes SousTitre="Envois Mail et SMS" titre={"0"}  avatar='./img/sms.png' lien={"/mails"}/>
      </Box>
      <Box sx={{display : mode , marginLeft :margegauche,flexWrap : "wrap", width: { sm: `calc(100% - ${drawerWidth}px)` },}}>
     <Cartes SousTitre="Liste des Rendez-vous" titre={sizeRDV}  avatar='./img/rdv.png' lien={"/rdv"}/>
     <Cartes SousTitre="Liste des paniers non valider" titre={sizeOtherPanier}  avatar='./img/orders.png' lien={"/other-panier"}/>
      </Box> 
      {isLoading}
    </div>
  );
};

export default Home;
