import React, { useState } from "react";
import NavBar from "../components/NavBar";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";
import { Box, Grid,CircularProgress } from "@mui/material";
import CartesOrders from "../components/minicomponents/CartesOrders";
const Orders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const largeur = window.innerWidth;
  let margegauche = 30;
  if (largeur < 601) {
    margegauche = 0;
  }
  React.useEffect(() => {
    getCommande();
  }, []);

  const getCommande = async () => {
    const docRef = collection(db, "commands");
    const q = query(docRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach((res) => {
      const {
        address,
        createdAt,
        id,
        isPaied,
        products,
        ref,
        transactionId,
        total,
        delivery,
        user,
        paymentMethod,
        payAtDelivery,
      } = res.data();
      if (isPaied || payAtDelivery) {
        dataArr.push({
          ID: res.id,
          address,
          createdAt,
          id,
          isPaied,
          products,
          ref,
          transactionId,
          total,
          delivery,
          user,
          paymentMethod,
          payAtDelivery,
        });
      }
    });
    setDocuments(dataArr);
    setIsLoading(false);
  };
  const filteredDocuments = documents.filter(doc => doc.isPaied === true || doc.payAtDelivery === true);
  if (isLoading) {
    return(
        <div>
            <NavBar/>
        <div>
        <Box>
        <CircularProgress />
        </Box>
        </div>
        </div>
    )

} else {  

  return (
    <div>
      <NavBar />
      <Grid container spacing={2} sx={{ marginLeft: margegauche }}>
            {
                filteredDocuments.map((commande)=>(
                    <CartesOrders key={commande.id} user={commande.user} adress={commande.address} date={commande.createdAt} total={commande.total} products={commande.products}  payer={commande.isPaied} livrer={commande.delivery} methode={commande.paymentMethod} typeDePaiement={commande.payAtDelivery} />
                ))
            }
      </Grid>
    </div>
  );
        }
};
export default Orders;
