// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCUkequJVGVdLL4_oOOgyj1mT1MgDukszg",
  authDomain: "nappylocks-56e13.firebaseapp.com",
  databaseURL: "https://nappylocks-56e13-default-rtdb.firebaseio.com",
  projectId: "nappylocks-56e13",
  storageBucket: "nappylocks-56e13.appspot.com",
  messagingSenderId: "934135400682",
  appId: "1:934135400682:web:3e9884a8071ae8cde1ef93",
  measurementId: "G-J95GBG14SS"
};
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
